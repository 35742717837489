import React from "react";
import * as THREE from 'three';
import { Canvas, useFrame, extend, useThree, useLoader } from '@react-three/fiber';
import { Suspense, useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
// import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass'
// import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { CameraShake, OrbitControls, useTexture, Text, Text3D } from '@react-three/drei';
// import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from "react-postprocessing"
// import { EffectComposer } from "react-postprocessing"
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette, Pixelation, GodRays, Glitch, SSAO, Outline, Blur } from '@react-three/postprocessing'
import { GlitchMode, BlendFunction, Resizer, KernelSize } from 'postprocessing';
import StatsImpl from 'stats.js';
import myFont from './font/SairaSemiCondensed-Regular.ttf';
import MatrixRain from "./MatrixRain";
import { Sky } from "@react-three/drei";
import { BackgroundContext } from './Contexts';
import circleImg from './asset/circle.png';
import { Button } from '@mui/material';
import { Vector3 } from "three";
import { cyan } from './App';
import { Reflector } from 'three/examples/jsm/objects/Reflector';

function ReflectiveFloor({ width = 800, height = 4000, color = 0x132020, rotX = -Math.PI / 2, transZ = -5 }) {
    const reflectorLeft = React.useRef();

    React.useEffect(() => {
        if (reflectorLeft.current) {
            const geometry = new THREE.PlaneGeometry(width, height);
            const reflector = new Reflector(geometry, {
                clipBias: 0.003,
                textureWidth: window.innerWidth * window.devicePixelRatio,
                textureHeight: window.innerHeight * window.devicePixelRatio,
                // color: 0x777777,
                // opacity: 0.4,
                color: 0x333333,
                // color: color, // Black color for transparency
                recursion: 1,
            });

            // Enable transparency in the reflector's material
            reflector.material.transparent = true;
            // Add a custom uniform for base color
            reflector.material.uniforms = {
                ...reflector.material.uniforms,
                uBaseColor: { value: new THREE.Color('#2f4f4f') }, // Custom plane color
                uOpacity: { value: 0.7 }, // Opacity for transparency
            };

            // Modify the shader
            reflector.material.onBeforeCompile = (shader) => {
                shader.uniforms.uBaseColor = reflector.material.uniforms.uBaseColor;
                shader.uniforms.uOpacity = reflector.material.uniforms.uOpacity;

                shader.fragmentShader = shader.fragmentShader.replace(
                    `gl_FragColor = vec4(base, 1.0);`, // Default shader output
                    `
          vec3 mixedColor = mix(uBaseColor.rgb, base, 0.5); // Blend base color with reflection
          gl_FragColor = vec4(mixedColor, uOpacity); // Add transparency
          `
                );
            };


            reflector.rotation.x = rotX; // Make it horizontal
            reflector.translateZ(transZ) // Make it horizontal
            // reflector.translateY(-250)
            reflectorLeft.current.add(reflector);
        }
    }, []);

    return <group ref={reflectorLeft} />;
}



// extend({ EffectComposer, RenderPass, GlitchPass, UnrealBloomPass })


function Stats() {
    const [stats] = useState(() => new StatsImpl())
    useEffect(() => {
        stats.showPanel(0);
        document.body.appendChild(stats.dom)
        return () => document.body.removeChild(stats.dom)
    }, [])
    return useFrame(state => {
        stats.begin()
        state.gl.render(state.scene, state.camera)
        stats.end()
    }, 1)
}


// function Points() {
//     const imgTex = useLoader(THREE.TextureLoader, circleImg)

//     let t = 0;
//     let f = 0.0001;
//     let a = 0.01;

//     const graph = useCallback((x, z) => {
//         return Math.sin(f * (x ** 2 + z ** 2 + t)) * a;
//     }, [t, f, a])

//     const count = 12;
//     const sep = 35;
//     const max = count * sep;
//     const corridor = 0.90;


//     let positions = useMemo(() => {
//         let positions = []

//         for (let xi = 0; xi < count; xi++) {
//             for (let zi = 0; zi < count; zi++) {
//                 let x = sep * (xi - count / 2)
//                 let z = sep * (zi - count / 2)
//                 let y = graph(x, z);
//                 // let y = 0;

//                 positions.push(x,y,z)
//                 if (zi < count / 2 * corridor || zi > count / 2 * (2 - corridor))
//                     positions.push([x, y, z])
//             }
//         }

//         return  new Float32Array(positions);
//     }, [count, sep])


//     return (
//         <points>
//             <bufferGeometry attach="geometry">
//                 <bufferAttribute
//                     attachObject={['attributes', 'position']}
//                     array={positions}
//                     count={positions.length / 3}
//                     itemSize={3}
//                 />
//             </bufferGeometry>

//             <pointsMaterial
//                 attach='material'
//                 map={imgTex}
//                 color={0x00AAFF}
//                 size={1}
//                 sizeAttenuation
//                 transparent={false}
//                 alphaTest={0.5}
//                 opacity={1.0}
//             />
//         </points>)
// }



function Point(props) {
    const ref = useRef();
    const texture = useLoader(THREE.TextureLoader, circleImg)

    useFrame(({ clock }, delta) => {
        const a = clock.getElapsedTime()
        // console.log(a)
        const x = ref.current.position.x;
        const z = ref.current.position.z;
        // if (props.rx)
        //     ref.current.rotation.x += props.rx;
        // if (props.ry)
        //     ref.current.rotation.y += props.ry;
        // if (props.rz)
        //     ref.current.rotation.z += props.rz;
        // if (props.rx)
        //     ref.current.rotation.x += 0.1;

        ref.current.position.y = Math.sin(0.001 * (x ** 2 + z ** 2 - a * 1000)) * 2 + props.offsets[1];
    })

    return (
        <mesh
            ref={ref}
            {...props}>
            <planeBufferGeometry attach="geometry" args={[3, 3]} />

            {/* <meshBasicMaterial attach="material" map={texture} /> */}
            <pointsMaterial
                attach='material'
                map={texture}
                color={props.color}
                size={1 * props.scale}
                sizeAttenuation
                transparent={false}
                alphaTest={0.5}
                opacity={1.0}
            />
        </mesh>
    )
}


// function Box(props) {
//     // This reference gives us direct access to the THREE.Mesh object
//     const ref = useRef();
//     // Hold state for hovered and clicked events
//     // const [hovered, hover] = useState(false)
//     // const [clicked, click] = useState(false)
//     // Subscribe this component to the render-loop, rotate the mesh every frame

//     // const graph = useCallback((x, z) => {
//     //     return Math.sin(f * (x ** 2 + z ** 2 + t)) * a;
//     // }, [t, f, a])

//     useFrame(({ clock }, delta) => {
//         const a = clock.getElapsedTime()
//         // console.log(a)
//         const x = ref.current.position.x;
//         const z = ref.current.position.z;
//         if (props.rx)
//             ref.current.rotation.x += props.rx;
//         if (props.ry)
//             ref.current.rotation.y += props.ry;
//         if (props.rz)
//             ref.current.rotation.z += props.rz;

//         ref.current.position.y = Math.sin(0.001 * (x ** 2 + z ** 2 - a * 1000)) * 2 + props.offsets[1];
//     })
//     // Return the view, these are regular Threejs elements expressed in JSX
//     return (
//         <mesh
//             {...props}
//             ref={ref}
//             scale={[props.scale, props.scale, props.scale]}
//         // onClick={(event) => click(!clicked)}
//         // onPointerOver={(event) => hover(true)}
//         // onPointerOut={(event) => hover(false)}
//         >
//             <boxGeometry attach='geometry' args={[1.8, 1.8, 1.8]} />
//             {/* <meshStandardMaterial attach='material' color={props.color} opacity={props.opacity} transparent={true} /> */}
//             {/* <meshBasicMaterial wireframe={false} color='cyan' /> */}
//             <meshPhysicalMaterial color={props.color} specularIntensity={5} roughness={0.6} transmission={0.998} thickness={1} />
//         </mesh>
//     )
// }



function Title(props) {

    const ref = useRef()
    var counter = 0;

    useFrame(({ clock }, delta) => {
        if (props.bounce) {
            const a = clock.getElapsedTime()
            // console.log(a)
            const x = ref.current.position.x;
            const z = ref.current.position.z;
            if (props.rx)
                ref.current.rotation.x += props.rx;
            if (props.ry)
                ref.current.rotation.y += props.ry;
            if (props.rz)
                ref.current.rotation.z += props.rz;
            // if (props.rx)
            //     ref.current.rotation.x += 0.1;

            ref.current.position.y = Math.sin(0.001 * (x ** 2 + z ** 2 - a * 1000)) * 4 + props.offsets[1];
        }
    })


    // Return the view, these are regular Threejs elements expressed in JSX
    return (
        <mesh
            {...props}
            ref={ref}
            // scale={0.2}
            onClick={(event) => { counter++; console.log(counter) }}
        // onPointerOver={(event) => hover(true)}
        // onPointerOut={(event) => hover(false)}
        >
            <Text
                scale={[props.scale, props.scale, props.scale]}
                color={props.color}
                anchorX={"center"}
                anchorY={"middle"}
                rotation={[0, 0.5 * Math.PI, 0]}
                font={myFont}
            >{props.children}
                {/* <meshPhysicalMaterial specularColor={props.color} specularIntensity={20} roughness={0.6} transmission={1} thickness={1} /> */}
            </Text>

        </mesh>
    )
}


function Rig({ children }) {
    const ref = useRef()
    const vec = new THREE.Vector3()
    const { camera, mouse } = useThree()
    useFrame(() => {
        // camera.position.lerp(vec.set(mouse.x * 2, 0, 3.5), 0.05)
        // ref.current.position.lerp(vec.set(mouse.x * 1, mouse.y * 0.1, 0), 0.1)
        ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, (-mouse.x * Math.PI) / 100, 0.1)
        ref.current.rotation.z = THREE.MathUtils.lerp(ref.current.rotation.z - 0.010, (-mouse.y * Math.PI) / 100, 0.1)
    })
    return <group ref={ref}>{children}</group>
}

function Points(props) {

    let t = 0;
    let f = 0.0001;
    let a = 0.01;

    // const graph = useCallback((x, z) => {
    //     return Math.sin(f * (x ** 2 + z ** 2 + t)) * a;
    // }, [t, f, a])

    const count = 18;
    const sep = 50;
    const max = count * sep;
    const corridor = 0.9;

    let positions = useMemo(() => {
        let positions = []

        for (let xi = 0; xi < count; xi++) {
            for (let zi = 0; zi < count; zi++) {
                let x = sep * (xi - count / 2)
                let z = sep * (zi - count / 2)
                let y = 0
                // let y = graph(x, z);

                if (zi < count / 2 * corridor || zi > count / 2 * (2 - corridor))
                    positions.push([x, y, z])
            }
        }

        return positions
    }, [count, sep])

    return (positions.map(c => <Point position={c} rotation={[0, 0.5 * Math.PI, 0]} scale={props.scale} rx={0.02} color={props.color} offsets={[0, 0, 0]} opacity={0.4} />))
}

// function Boxes(props) {

//     let t = 0;
//     let f = 0.0001;
//     let a = 0.01;

//     // const graph = useCallback((x, z) => {
//     //     return Math.sin(f * (x ** 2 + z ** 2 + t)) * a;
//     // }, [t, f, a])

//     const count = 6;
//     const sep = 70;
//     const max = count * sep;
//     const corridor = 0.95;

//     let positions = useMemo(() => {
//         let positions = []

//         for (let xi = 0; xi < count; xi++) {
//             for (let zi = 0; zi < count; zi++) {
//                 let x = sep * (xi - count / 2)
//                 let z = sep * (zi - count / 2)
//                 // let y = graph(x, z);
//                 let y = 0;

//                 if (zi < count / 2 * corridor || zi > count / 2 * (2 - corridor))
//                     positions.push([x, y, z])
//             }
//         }

//         return positions
//     }, [count, sep])

//     return (positions.map(c => <Box position={c} rotation={[Math.random(), Math.random(), 0]} scale={props.scale} rx={0.02} color={props.color} offsets={[0, 0, 0]} opacity={0.4} />))
// }






const Box = React.memo(({ position, scale, color, opacity, rx = 0.02 }) => {
    const meshRef = useRef();

    useFrame(() => {
        if (meshRef.current) {
            meshRef.current.rotation.x += rx;
            meshRef.current.rotation.y += rx;
        }
    });

    return (
        <mesh
            ref={meshRef}
            position={position}
            rotation={[Math.random(), Math.random(), 0]}
            frustumCulled
            castShadow
            receiveShadow
        >
            <boxGeometry args={[scale[0], scale[1], scale[2]]} />
            {/* <meshStandardMaterial color={color} transparent opacity={opacity} /> */}
            <meshPhysicalMaterial color={color} specularIntensity={5} roughness={0.6} transmission={0.998} thickness={1} />
        </mesh>
    );
});

function Boxes(props) {
    const count = props.count;
    const sep = props.sep;
    const corridor = props.corridor;

    let positions = [];

    for (let xi = 0; xi < count; xi++) {
        for (let zi = 0; zi < count; zi++) {
            let x = sep * (xi - count / 2);
            let z = sep * (zi - count / 2);
            let y = 0;

            if (zi < count / 2 * corridor || zi > count / 2 * (2 - corridor)) {
                positions.push([x, y, z]);
            }
        }
    }

    return positions.map((pos, index) => (
        <Box
            key={index}
            position={pos}
            scale={props.scale}
            rx={props.rx}
            color={props.color}
            opacity={props.opacity}
        />
    ));
}

// export const Boxes = React.memo(Boxs);

const Sun = forwardRef(function Sun(props, forwardRef) {
    useFrame(({ clock }) => {
        forwardRef.current.position.x = Math.sin(clock.getElapsedTime()) * -8;
        forwardRef.current.position.y = Math.cos(clock.getElapsedTime()) * -8;
    });

    return (
        <mesh ref={forwardRef} position={[0, 0, -15]}>
            <sphereGeometry args={[1, 36, 36]} />
            <meshBasicMaterial color={"#ca6924"} />
        </mesh>
    );
});



function CameraControls(props) {
    const vec = new Vector3();

    useFrame((state) => {
        const step = 0.01;
        const defPos = props.focus && props.focus.defPos ? props.focus.defPos : [200, 30, 0];
        const lookAt = props.focus && props.focus.lookAt ? props.focus.lookAt : [-200, 10, 0];

        if (props.focus !== null) {
            vec.set(props.focus.x, props.focus.y, props.focus.z + 0.2);
            state.camera.lookAt(lookAt[0], lookAt[1], lookAt[2]);
            state.camera.position.lerp(vec, step);
            state.camera.updateProjectionMatrix();
        }
        else {
            vec.set(defPos[0], defPos[1], defPos[2]);
            state.camera.lookAt(lookAt[0], lookAt[1], lookAt[2]);
            state.camera.position.lerp(vec, step);
            state.camera.updateProjectionMatrix();
        }
    })

    return null
}

function AnimationCanvas(props) {
    // const { runloop, setRunloop } = useState('always');
    const canvasRef = useRef();
    const [focusing, setFocusing] = useState(null);



    // const d = useEffect()
    // renderer.setPixelRatio( window.devicePixelRatio );
    // renderer.setSize( innerWidth, innerHeight );

    //     <div className="App"
    //     style={{ background: `linear-gradient(#141414, #132020 , #2f4f4f)`, overflowX: 'hidden', overflowY: 'scroll' }}
    //   >

    return <BackgroundContext.Consumer>

        {(context) =>
        (<Canvas
            ref={canvasRef}
            // {...props}
            style={{
                // background: `linear-gradient(#141414, #132020 , #2f4f4f)`,
                top: 0, position: 'fixed',
                width: '100vw',
                // width: 'min(1500px, 100vw)',
                // left: 'calc((100vw - min(1500px, 100vw)) / 2)',
                height: '100vh', overflowY: 'hidden',
                opacity: 0.4
            }}
            colorManagement={true}
            camera={{ position: [0, 80, 0], fov: 90 }}
            gl={{ antialias: true, concurrent: true, alpha: true }}



            // gl2={true}
            // concurrent={true}
            // orthographic={true}
            // noEvents={false}
            // invalidateFrameloop={true}
            frameloop={context.bgAnimate}


        // onCreated={({ gl }) => { gl.setClearColor(new THREE.Color("#282c34")); }}
        >

            {/* <color attach="background" args={[null]} /> */}
            {/* <GradientBackground /> */}

            {/* Light */}
            {/* <ambientLight intensity={1} />
            <directionalLight position={[10, 100, 100]} intensity={5} /> */}

            {/* Reflective Floor */}
            {/* <ReflectiveFloor color={0x2f4f4f} /> */}

            {/* <Camera  position={[15, 150, 80]} fov={75} /> */}

            {/* <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} /> */}

            <Suspense fallback={null}>

                {/* Gradient Background */}
                {/* <color attach="background" args={['#141414']} /> */}
                {/* <fog attach="fog" args={['#141414', 10, 50]} /> */}


                {/* <Sky
                        distance={450000000}
                        sunPosition={[5, 1, 8]}
                        inclination={0}
                        azimuth={0.25}
                        {...props}
                    /> */}
                {/* <Bloom> */}
                {/* <ambientLight /> */}
                {/* <pointLight position={[50, 150, 0]} intensity={100}/> */}

                <ambientLight intensity={10} />
                <directionalLight position={[10, 100, 100]} intensity={3} />

                {/* <House position={[0, 0, 0]} /> */}
                {/* <Rig> */}


                {/* Reflective Floor */}
                <ReflectiveFloor color={0x2f4f4f} />


                <Boxes
                    count={10}
                    sep={70}
                    corridor={0.95}
                    scale={[2.5, 2.5, 2.5]}
                    color={cyan(3)}
                    opacity={0.01}
                    rx={0.008}
                // randomRotation={true}
                // wave={{
                //     enabled: true,
                //     frequency: 0.0001,
                //     amplitude: 0.01
                // }}
                />
                {/* <Boxes position={[0, 0, 0]} color={cyan(3)} scale={2.5}   opacity={0.4} /> */}
                <group>

                    {/* <Points position={[0, 0, 0]} color={'#0d5b4e'} scale={1.2}/> */}
                    {/* <Title bounce={true} position={[-300, 0, 0]} offsets={[0, 95, 0]} scale={20} color={'#0d5b4e'} opacity={0.8}>DATACORRIDOR</Title> */}
                    {/* <Title bounce={true} position={[-300, 0, 0]} offsets={[0, 52, 0]} scale={width < height ? 12 : 16} color={'silver'} opacity={0.6}>SaaS Innovation House</Title> */}
                    {/* <Title bounce={true} position={[-300, 0, 0]} offsets={[0, 25, 0]} scale={width < height ? 12 : 16} color={'silver'} opacity={0.7}> {JSON.stringify(context.frameloop)}</Title> */}
                    {/* <Corridor position={[0, -10, 0]} offsets={[0, 0, 0]} color={'cyan'} /> */}
                    {/* [0.5 * Math.PI, 1 * Math.PI,0.5 * Math.PI] */}

                    {/* <MatrixRain key={1} mode='horizontal' delay={props.rainDelay + 200} reversed={false} maxOpacity={0.1} decayOpacity={0.4} position={[-400, 155, -250]} length={40} spacing={30} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={4} color={'silver'} /> */}

                    <MatrixRain key={2} mode='horizontal' delay={props.rainDelay - 300} reversed={true} maxOpacity={0.08} decayOpacity={0.4} position={[-400, 60, 250]} length={40} spacing={30} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={20} color={'silver'} />
                    <MatrixRain key={3} mode='horizontal' delay={props.rainDelay - 100} reversed={false} maxOpacity={0.12} decayOpacity={0.4} position={[-400, 95, -250]} length={40} spacing={30} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={20} color={cyan(3)} />
                    <MatrixRain key={4} mode='horizontal' delay={props.rainDelay + 300} reversed={true} maxOpacity={0.08} decayOpacity={0.4} position={[-400, 130, 250]} length={40} spacing={30} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={20} color={'silver'} />
                    <MatrixRain key={5} mode='horizontal' delay={props.rainDelay + 200} reversed={false} maxOpacity={0.1} decayOpacity={0.4} position={[-400, 165, -250]} length={40} spacing={30} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={20} color={cyan(3)} />
                    <MatrixRain key={6} mode='horizontal' delay={props.rainDelay - 200} reversed={true} maxOpacity={0.12} decayOpacity={0.4} position={[-400, 200, 250]} length={40} spacing={30} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={20} color={'silver'} />
                    <MatrixRain key={7} mode='horizontal' delay={props.rainDelay + 200} reversed={false} maxOpacity={0.1} decayOpacity={0.4} position={[-400, 235, -250]} length={40} spacing={30} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={20} color={cyan(3)} />
                    <MatrixRain key={8} mode='horizontal' delay={props.rainDelay - 200} reversed={true} maxOpacity={0.12} decayOpacity={0.4} position={[-400, 270, 250]} length={40} spacing={30} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={20} color={'silver'} />

                    <MatrixRain key={7} mode='horizontal' baseMesh={{ rotation: [0.5 * Math.PI, 0.5 * Math.PI, 0] }} delay={props.rainDelay - 150} reversed={true} maxOpacity={0.6} decayOpacity={0.6} position={[-4, 15, 120]} length={31} spacing={12} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={5} color={'silver'} />
                    <MatrixRain key={8} mode='horizontal' baseMesh={{ rotation: [0.5 * Math.PI, 0.5 * Math.PI, 0] }} delay={props.rainDelay + 100} reversed={false} maxOpacity={0.3} decayOpacity={0.6} position={[-4, 5, -180]} length={29} spacing={12} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={5} color={'#0d5b4e'} />
                    <MatrixRain key={9} mode='horizontal' baseMesh={{ rotation: [0.5 * Math.PI, 0.5 * Math.PI, 0] }} delay={props.rainDelay - 350} reversed={true} maxOpacity={0.4} decayOpacity={0.6} position={[-4, -5, 140]} length={30} spacing={12} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={5} color={'#0d5b4e'} />
                    <MatrixRain key={10} mode='horizontal' baseMesh={{ rotation: [0.5 * Math.PI, 0.5 * Math.PI, 0] }} delay={props.rainDelay - 200} reversed={false} maxOpacity={0.5} decayOpacity={0.6} position={[-4, -15, -200]} length={31} spacing={12} opacity={0} offsets={[0, 0, 0]} rotation={[0, 0.5 * Math.PI, 0]} scale={5} color={'silver'} />
                </group>
                {/* <Title position={[110, 0, 0]} offsets={[0, 0, 0]} scale={width < height ? 3 : 3.2} color={'silver'} opacity={0.3}>&#169; 2022 DATA CORRIDOR LIMITED.</Title> */}
                {/* </Rig> */}

                {/* <Sun ref={sunRef} /> */}

                <EffectComposer>
                    {/* <RenderPass /> */}
                    {/* <Blur kernelSize={5} /> */}
                    {/* <SSAO /> */}
                    {/* <Outline/> */}
                    {/* <DepthOfField focusDistance={1} focalLength={0.2} bokehScale={2} height={480} /> */}
                    <Bloom luminanceThreshold={0.05} luminanceSmoothing={0.6} height={1000} opacity={0.2} />
                    <Noise opacity={0.02} />
                    <Vignette eskil={true} offset={0.1} darkness={1.1} />
                    {/* <Pixelation /> */}
                    {/* <Glitch delay={[7, 20]} duration={[0.3, 0.6]} mode={GlitchMode.SPORADIC} /> */}
                </EffectComposer>

                {/* <ResolutionConfigurator width={500} height={500} /> */}

            </Suspense>
            {/* <Stats /> */}

            {/* <CameraShake yawFrequency={0.2} pitchFrequency={0.1} rollFrequency={0.1} /> */}

            {/* <Button style={{ zIndex: 100, position: 'absolute', top: '0px', left: '0px', width: "50px", height: "50px" }}
                    onClick={() => { console.log('hello') }}> Click </Button> */}
            <CameraControls focus={context.focusing} />

        </Canvas >

        )}
    </BackgroundContext.Consumer>
}


function Background(props) {
    // return (<div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
    //     <div style={{ zIndex: 1, height: '100vh', width: '100vw', position: 'fixed', overflowX: 'hidden', overflowY: 'hidden' }} />
    //     <AnimationCanvas {...props} />
    // </div>)

    return (<AnimationCanvas {...props} />)
}


function ResolutionConfigurator(props) {

    // const [initRes, setInitRes] = useState(false);
    const { gl } = useThree();

    useEffect(() => {
        gl.setPixelRatio(window.devicePixelRatio * 1.0);
        // gl.setSize(props.width, props.height);
        console.log(gl.info);
        console.log("initres")
    }, []);

    // if (!initRes){
    //     console.log("initRes")
    //     setInitRes(true);
    // }

    return null;
}

export default Background;