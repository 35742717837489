import React, { useState } from 'react';
import { Menu, X, MapPin, BookOpen, ChevronRight, Github, ExternalLink } from 'lucide-react';
import { delay, motion } from 'framer-motion';

export const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
};

export const staggerContainer = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const Navigation = ({ isOpen, setIsOpen }) => (
  <motion.nav
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ type: "ease-in", stiffness: 20, duration: 1, delay: 4 }}
    className="fixed w-screen backdrop-blur-md bg-warmGray-950/50 border-b border-emerald-950/50 z-50"
  >
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-16">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2 }}
          className="flex items-center"
        >
          <span className="text-primary-default text-xl font-bold tracking-wider [text-shadow:_0_2px_4px_rgb(40_40_40_/_0.4)] cursor-default">
            DATACORRIDOR
          </span>
        </motion.div>

        <div className="hidden md:block">
          <motion.div
            variants={staggerContainer}
            initial="initial"
            animate="animate"
            className="ml-10 flex items-baseline space-x-8"
          >
            {["HOME", "EXPERTISE", "PROJECTS"].map((item) => (
              <motion.a
                key={item}
                variants={fadeIn}
                href={`#${item.toLowerCase()}`}
                className="text-zinc-400 hover:text-primary-default px-3 py-2 text-sm tracking-wider transition-colors"
              >
                {item}
              </motion.a>
            ))}
          </motion.div>
        </div>

        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-zinc-400 hover:text-primary-default"
          >
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>
    </div>

    {isOpen && (
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        className="md:hidden absolute w-full bg-zinc-950/90 backdrop-blur-lg"
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {["HOME", "EXPERTISE", "PROJECTS"].map((item) => (
            <motion.a
              key={item}
              variants={fadeIn}
              href={`#${item.toLowerCase()}`}
              className="text-zinc-400 hover:text-primary-default block px-3 py-2 text-sm tracking-wider"
              onClick={() => setIsOpen(!isOpen)}
            >
              {item}
            </motion.a>
          ))}
        </div>
      </motion.div>
    )}
  </motion.nav>
);

const ProductCard = ({ title, description, tags, icon: Icon, className, index, href }) => (
  <motion.div
    variants={{
      initial: { opacity: 0, y: 50 },
      animate: { opacity: 1, y: 0 }
    }}
    initial="initial"
    whileInView="animate"
    viewport={{ once: true }}
    transition={{ delay: index * 0.2 }}
    className={`group relative p-6 rounded-sm border bg-warmGray-200/10 border-emerald-900/40 backdrop-blur-sm hover:border-emerald-800/50 transition-all duration-300 shadow-lg ${className}`}
  >
    {/* <div className="absolute inset-0 bg-gradient-to-br from-emerald-950/5 to-transparent rounded-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div> */}
    <div className="relative">
      <div className="flex items-center mb-4 [text-shadow:_0_2px_4px_rgb(0_0_0_/_0.2)]">
        <Icon className="text-primary-default mr-3" size={24} />
        <h3 className="text-xl font-semibold text-primary-default tracking-wide">{title}</h3>
      </div>
      <p className="text-zinc-400 leading-relaxed mb-4">{description}</p>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className="flex flex-wrap gap-2 mb-4"
      >
        {tags.map((tag, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ delay: index * 0.1 }}
            className="text-xs text-zinc-400 px-2 py-1 border border-emerald-900/60 rounded-sm bg-zinc-900/60 tracking-wider drop-shadow-md shadow-md"
          >
            {tag}
          </motion.span>
        ))}
      </motion.div>
      {href && <motion.button
        whileHover={{ x: 5 }}
        className="flex items-center text-primary-default hover:text-emerald-500 transition-colors group"
        onClick={() => window.open(href, "_blank")}
      >
        EXPLORE PROJECT
        <ExternalLink className="ml-2 w-4 h-4" />
      </motion.button>}
    </div>
  </motion.div>
);

const Homepage2 = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (<>
    <Navigation isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
    <div className=" bg-warmGray-950/50 text-white min-h-screen overflow-y-auto overflow-x-hidden">

      {/* Hero Section */}
      <motion.section
        initial="initial"
        animate="animate"
        variants={staggerContainer}
        id="home"
        className="relative pt-32 pb-20 px-4 overflow-hidden"
      >
        <motion.div
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.1, 0.3, 0.1]
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            repeatType: "reverse"
          }}
          className="absolute top-0 left-1/2 -translate-x-1/2 w-1/2 h-1/2 bg-emerald-950/10 blur-3xl rounded-full"
        />
        <div className="max-w-7xl mx-auto text-center relative">
          <motion.div className="flex flex-col items-center h-[78vh]">
            {children}
          </motion.div>

        </div>
      </motion.section>

      <div className="relative pt-2 pb-20 px-6 bg-warmGray-800/20 backdrop-blur-lg border-t-2 border-emerald-900/40">

        <br />

        <section id="expertise" >
          <br />
          <br />
          <br />
          {/* Product section background effect */}
          {/* <motion.div
            className="absolute inset-0 -z-10"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <div className="absolute left-0 w-full h-full bg-gradient-to-b from-emerald-950/5 to-transparent" />
            <motion.div
              className="absolute -right-1/4 top-1/4 w-1/2 h-1/2 bg-emerald-950/10 rounded-full blur-3xl"
              animate={{
                x: [-50, 50, -50],
                y: [-20, 20, -20],
                scale: [0.9, 1.1, 0.9],
                opacity: [0.1, 0.15, 0.1],
              }}
              transition={{
                duration: 25,
                repeat: Infinity,
                ease: "linear"
              }}
            />
          </motion.div> */}
          <div className="max-w-7xl mx-auto">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-3xl font-bold mb-12 text-center text-primary-default tracking-wide [text-shadow:_0_2px_4px_rgb(0_0_0_/_0.2)]"
            >
              OUR EXPERTISE
            </motion.h2>

            <motion.div className="flex flex-col items-center space-y-6">

              <motion.div
                variants={staggerContainer}
                initial="initial"
                animate="animate"
                className="flex flex-wrap justify-center gap-4 text-zinc-400 text-sm tracking-wider"
              >
                {["CUTTING-EDGE AI", "DATA SCIENCE", "GENERATIVE AI", "INTERACTIVE SYSTEMS"].map((tag, index) => (
                  <motion.span
                    key={tag}
                    variants={fadeIn}
                    // transition={{delay: 2, duration: 0.6}}
                    className="px-3 py-1 border border-emerald-900/60 rounded-sm bg-zinc-900/60 drop-shadow-md shadow-md"
                  >
                    {tag}
                  </motion.span>
                ))}
              </motion.div>
              <motion.p
                variants={fadeIn}
                // transition={{ delay: 2, duration: 0.6 }}
                className="text-lg text-zinc-400 max-w-2xl mx-auto tracking-wide leading-relaxed"
              >
                We turn visionary ideas into revolutionary solutions. Our expertise in AI and data science
                drives the creation of intelligent systems that redefine what's possible. From advanced data science to groundbreaking interactive systems, we transform ambitious visions into
                real-world innovations that define the future.

                {/* Through seamless fusion of AI  */}
                {/* and human interaction, we craft experiences that push the boundaries of digital engagement. */}

                {/* We're obsessed with pushing technological boundaries.  */}


              </motion.p>
            </motion.div>
          </div>
        </section>



        {/* Products Section */}
        <section id="projects" >
          <br />
          <br />
          <br />
          {/* Product section background effect */}
          {/* <motion.div
            className="absolute inset-0 -z-10"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <div className="absolute left-0 w-full h-full bg-gradient-to-b from-emerald-950/5 to-transparent" />
            <motion.div
              className="absolute -right-1/4 top-1/4 w-1/2 h-1/2 bg-emerald-950/10 rounded-full blur-3xl"
              animate={{
                x: [-50, 50, -50],
                y: [-20, 20, -20],
                scale: [0.9, 1.1, 0.9],
                opacity: [0.1, 0.15, 0.1],
              }}
              transition={{
                duration: 25,
                repeat: Infinity,
                ease: "linear"
              }}
            />
          </motion.div> */}
          <div className="max-w-7xl mx-auto">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-3xl font-bold mb-12 text-center text-primary-default tracking-wide [text-shadow:_0_2px_4px_rgb(0_0_0_/_0.2)]"
            >
              OUR PROJECTS
            </motion.h2>
            <div className="grid md:grid-cols-2 gap-8">
              <ProductCard
                index={0}
                // href={'https://taleweaver.io'}
                title="Taleweaver"
                description="Embark on AI-powered fantasy adventures in the style of D&D. Create your story through choices or free-form prompts, interact with dynamic characters, and shape your narrative in a rich fantasy world. Complete creative freedom meets intelligent storytelling."
                tags={["D&D STYLE", "FANTASY RPG", "AI STORYTELLING", "INTERACTIVE FICTION"]}
                icon={BookOpen}
                className="transform hover:-translate-y-1 transition-all duration-300"
              />
              <ProductCard
                index={1}
                // href={'https://taleweaver.io'}
                title="Echoplot"
                description="An AI-powered historical discovery platform that brings the past to life. Users explore historical events, figures, and locations through natural language prompts, revealing interconnected timelines and geographical connections across history."
                tags={["HISTORICAL AI", "TIMELINE GENERATION", "GEOSPATIAL MAPPING", "KNOWLEDGE DISCOVERY"]}
                icon={MapPin}
                className="transform hover:-translate-y-1 transition-all duration-300"
              />
            </div>
          </div>
        </section>
        <br />
      </div>

      {/* Footer */}
      <footer className="bg-warmGray-950/80 backdrop-blur-sm border-t border-emerald-900/50 py-6 w-screen transition-all duration-300 drop-shadow-md shadow-md">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <p className="text-zinc-400 tracking-wide text-sm [text-shadow:_0_2px_2px_rgb(0_0_0_/_0.4)]">© 2025 DATA CORRIDOR LIMITED. ALL RIGHTS RESERVED.</p>
        </div>
      </footer>
    </div>
  </>
  );
};

export default Homepage2;