import React, { useEffect, useState, useRef } from 'react';
import { useTrail, a, useTransition, animated, useSpring } from '@react-spring/web';


export const Transvoider = ({ className, open, duration, children, springs, behaviour, springConfig, behaviourConfig, containerRef, ref, containerStyle, closeCallback }) => {
    const items = React.Children.toArray(children);
    const [majorOpen, setMajorOpen] = useState(open);

    const _behaviours = items.length !== 1 ? {
        opacity: open ? 1 : 0,
        x: open ? 0 : 20,
        // scale: open ? 1 : 0.5,
        height: open ? 50 : 0,
        from: { opacity: 0, x: 20, height: 0 },
        ...behaviour,
        ...springs,
        config: { mass: 5, tension: 2000, friction: 200, ...behaviour?.config, ...behaviourConfig },
    } : {
        from: { opacity: open ? 0 : 1},
        to: { opacity: open ? 1 : 0},
        ...springs,
        ...behaviour,
        config: {
            mass: 5, tension: 2000, friction: 200,
            duration: duration ? duration : 100,
            ...behaviour?.config,
            ...behaviourConfig,
            ...springConfig
        },
    }

    const trail = useTrail(items.length, { ..._behaviours });
    const [props, api] = useSpring(() => (_behaviours), [open]);

    useEffect(() => {
        if (open)
            setMajorOpen(true)
        else
            setTimeout(() => { setMajorOpen(false); closeCallback && closeCallback() }, duration ? duration : 200)
    }, [open])

    return items.length !== 1 && majorOpen ?
        trail.map(({ height, ...style }, index) => (
            <a.div className={className} ref={containerRef} key={index} style={{ ...style }}>
                <a.div ref={ref} style={{ height }}>{items[index]}</a.div>
            </a.div>))
        : <div style={{ position: 'absolute', zIndex: 3, ...containerStyle }}>
            <animated.div ref={containerRef} style={{ ...props }}>
                {open && <div >
                    {children}
                </div>}
            </animated.div>
        </div>

    // return items.length > 1 ? <div style={{ ...containerStyle }}>
    //     {majorOpen && (trail.map(({ height, ...style }, index) => (
    //         <a.div className={className} ref={containerRef} key={index} style={{ ...style }}>
    //             <a.div ref={ref} style={{ height }}>{items[index]}</a.div>
    //         </a.div>)))}
    // </div> : <div style={{ position: 'absolute', zIndex: 3, ...containerStyle }}>
    //     {majorOpen && <animated.div ref={containerRef} style={{ ...props }}>
    //         <div >
    //             {children}
    //         </div>
    //     </animated.div>}
    // </div>
}

export const Trailer = ({ className, open, children, behaviour, behaviourConfig, containerRef, ref }) => {
    const items = React.Children.toArray(children);
    const trail = useTrail(items.length, {
        opacity: open ? 1 : 0,
        x: open ? 0 : 20,
        height: open ? 50 : 0,
        from: { opacity: 0, x: 20, height: 0 },
        ...behaviour,
        config: { mass: 5, tension: 2000, friction: 200, ...behaviour?.config, ...behaviourConfig },
    });
    return (trail.map(({ height, ...style }, index) => (
        <a.div className={className} ref={containerRef} key={index} style={{ ...style }}>
            <a.div ref={ref} style={{ height }}>{items[index]}</a.div>
        </a.div>)));
};

export const Transitioner = ({ open, exitDuration, springs, style, children, containerRef, springConfig }) => {

    const [majorOpen, setMajorOpen] = useState(false);
    const [props, api] = useSpring(
        () => ({
            from: { opacity: open ? 0 : 1, transform: "translate3d(0,-200px,0)" },
            to: { opacity: open ? 1 : 0, transform: "translate3d(0,0px,0)" },
            ...springs,
            config: {
                mass: 5, tension: 2000, friction: 200,
                duration: exitDuration ? exitDuration : 100,
                ...springs?.config,
                ...springConfig
            },
        }),
        [open])

    useEffect(() => {
        if (open)
            setMajorOpen(true)
        else
            setTimeout(() => setMajorOpen(false), exitDuration ? exitDuration : 100)
    }, [open])



    // <div style={{ position: 'absolute', zIndex: 8 }} >
    {/* <Draggable ref={containerRef} {...dragHandlers} positionOffset={{ x: '10px', y: '60px' }}> */ }
    {/* <animated.div ref={ref} style={style}> */ }

    return <div style={{ position: 'absolute', zIndex: 3, ...style }}>
        {majorOpen && <animated.div ref={containerRef} style={{ ...props }}>
            <div >
                {children}
            </div>
        </animated.div>}
    </div>
};

export const spanCharacters = (string,className, style ) => Array.from(string).map(segment => <span className={className} style={style}>{segment.replace(' ', "\u00A0")}</span>);
export const spanArray = (array,className, style) => array.map(segment => <span className={className} style={style}>{segment.replace(' ', "\u00A0")}</span>);
export const spanArrayStringEqualSplit = (str, n) => {
    let chunks = [];
    for (var i = 0, charsLength = str.length; i < charsLength; i += n) {
        chunks.push(str.replace(' ', "\u00A0").substring(i, i + n));
    }
    return chunks;
};

export const useElementOnScreen = (options) => {
    const containerRef = useRef(null);
    const permanent = options?.permanent || false
    const [isVisible, setIsVisible] = useState(false);

    const cb = (entries) => {
        const [entry] = entries;
        if (permanent && isVisible)
            return
        setIsVisible(entry.isIntersecting);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(cb, options);
        if (containerRef.current) observer.observe(containerRef.current);
        return () => {
            if (containerRef.current) observer.unobserve(containerRef.current);
        };
    }, [containerRef, options]);
    return [containerRef, isVisible];
};
