// import React, { Suspense, useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react'
// import * as THREE from 'three';
// import { Canvas, useFrame, extend, useThree } from 'react-three-fiber';
// import { Reflector, CameraShake, OrbitControls, useTexture, Text, Text3D } from '@react-three/drei';
import myFont from './font/SairaSemiCondensed-Regular.ttf';


// const useInterval = (callback, delay) => {
//     const savedCallback = React.useRef();

//     React.useEffect(() => {
//         savedCallback.current = callback;
//     }, [callback]);

//     React.useEffect(() => {
//         function tick() {
//             savedCallback.current();
//         }
//         let id = setInterval(tick, delay);
//         return () => clearInterval(id);
//     }, []);
// };

// function MatrixRain(props) {
//     // const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789                        "
//     // const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ                        "
//     // const chars = "DATACORRIDOR   あいうえ                     "
//     const chars = "00I   "

//     const ref = useRef()
//     var text = null
//     const rainMesh = useRef()

//     const maxChars = props.length

//     useInterval(() => {

//         if (text === null) {
//             text = []

//             for (let i = 0; i < maxChars; i++) {
//                 text.push(chars.charAt(Math.floor(Math.random() * chars.length)))
//             }
//         }
//         else if (text.length === maxChars) {
//             text.shift()
//             text.push(chars.charAt(Math.floor(Math.random() * chars.length)))

//             for (let i = 0; i < text.length; i++) {
//                 rainMesh.current.children[i].children[0].text = text[i]
//             }
//         }
//         else
//             text.push(chars.charAt(Math.floor(Math.random() * chars.length)))

//         if (text && rainMesh.current) {
//             for (let i = 0; i < text.length; i++) {
//                 if (text[i] == 'D' || text[i] == 'A' || text[i] == 'T' || text[i] == 'C' || text[i] == 'O' || text[i] == 'R' || text[i] == 'I')
//                     rainMesh.current.children[i].children[0].fillOpacity = props.maxOpacity
//                 else
//                     rainMesh.current.children[i].children[0].fillOpacity *= props.decayOpacity
//             }
//         }

//     }, props.delay)


//         return <mesh ref={rainMesh} {...props.baseMesh}>{[...Array(maxChars).keys()].map((c) => <mesh
//             {...props}
//             ref={ref}
//             position={[props.position[0], props.position[1], props.position[2] + (c) * props.spacing * (props.reversed ? -1 : 1)]}
//         >
//             <Text
//                 scale={[props.scale, props.scale, props.scale]}
//                 color={props.color}
//                 fillOpacity={props.opacity}
//                 anchorX={"center"}
//                 anchorY={"middle"}
//                 // rotation={props.rotation}
//                 font={myFont}
//             >{0}
//                 {/* <meshPhysicalMaterial specularColor={props.color} specularIntensity={5} roughness={0.7} transmission={1} thickness={1} /> */}
//             </Text>
//         </mesh>)}
//         </mesh>
// }

// export default MatrixRain;



import React, { useRef, useMemo, useCallback } from 'react';
import { Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

const MatrixRain = ({
  length = 20,
  position = [0, 0, 0],
  spacing = 0.5,
  scale = 1,
  color = '#00ff00',
  reversed = false,
  delay = 1000,
  maxOpacity = 1,
  decayOpacity = 0.95,
  mode = 'vertical',
  baseMesh = {},
  rotation = [0, 0, 0],
  opacity = 1,
  offsets = [0, 0, 0],
  characters = "00I   "
}) => {
  const rainMesh = useRef();
  const timeRef = useRef(0);
  const lastUpdateRef = useRef(0);
  
  // Memoize the initial text array
  const textArray = useMemo(() => 
    Array(length).fill('').map(() => ({
      char: characters[Math.floor(Math.random() * characters.length)],
      opacity: maxOpacity
    })), [length, characters, maxOpacity]);

  // Calculate if character should be highlighted (non-space)
  const isHighlightChar = useCallback((char) => {
    return char !== ' ';
  }, []);
  
  // Memoize character update function
  const updateChar = useCallback((currentChar) => {
    const newChar = characters[Math.floor(Math.random() * characters.length)];
    return {
      char: newChar,
      opacity: isHighlightChar(newChar) ? maxOpacity : currentChar.opacity * decayOpacity
    };
  }, [characters, maxOpacity, decayOpacity]);

  // Calculate position based on mode
  const getPosition = useCallback((basePos, index) => {
    const offset = spacing * index * (reversed ? -1 : 1);
    if (mode === 'horizontal') {
      return [
        basePos[0] + offsets[0],
        basePos[1] + offsets[1],
        basePos[2] + offset + offsets[2]
      ];
    }
    return [
      basePos[0] + offsets[0],
      basePos[1] + offset + offsets[1],
      basePos[2] + offsets[2]
    ];
  }, [mode, spacing, reversed, offsets]);

  // Use frame-based animation with delay support
  useFrame((state, delta) => {
    timeRef.current += delta * 1000; // Convert to milliseconds
    
    if (timeRef.current - lastUpdateRef.current >= delay) {
      if (!rainMesh.current) return;
      
      // Shift array and update characters
      for (let i = 0; i < rainMesh.current.children.length - 1; i++) {
        const textMesh = rainMesh.current.children[i].children[0];
        const nextTextMesh = rainMesh.current.children[i + 1].children[0];
        
        textMesh.text = nextTextMesh.text;
        textMesh.fillOpacity = nextTextMesh.fillOpacity;
      }
      
      // Update last character
      const lastIndex = rainMesh.current.children.length - 1;
      const lastMesh = rainMesh.current.children[lastIndex].children[0];
      const updated = updateChar({ char: lastMesh.text, opacity: lastMesh.fillOpacity });
      lastMesh.text = updated.char;
      lastMesh.fillOpacity = updated.opacity;
      
      lastUpdateRef.current = timeRef.current;
    }
  });

  return (
    <mesh ref={rainMesh} {...baseMesh}>
      {textArray.map((char, index) => (
        <mesh
          key={index}
          position={getPosition(position, index)}
          rotation={rotation}
        >
          <Text
            scale={[scale, scale, scale]}
            color={color}
            fillOpacity={char.opacity * opacity}
            anchorX="center"
            anchorY="middle"
            font={myFont}
          >
            {char.char}
          </Text>
        </mesh>
      ))}
    </mesh>
  );
};

export default React.memo(MatrixRain);